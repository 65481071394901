<template>
  <div class="edit-item-report">
    <my-dialog :show="show" @close="close" v-if="originalRecord && record" :max-width="'600px'">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('editItemReport')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div v-html="__('reportItemExplanation')"></div>
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <label>{{ __('entity.common.item') }}</label>
              <select-reference
                  v-model="record.itemId"
                  :endpoint="'/ingame/items/item'"
                  :field-icon="'icon'"
                  :field-title="'fullName'"
                  :nothing-selected-message="__('common.no_item_selected')"
                  :prepend-icon="isChanged('itemId') ? 'pencil' : ''"
                  @input="registerChange('itemId')"
              />
            </div>
            <div class="flex xs12">
              <text-area
                  v-model="record.description"
                  :label="__('entity.common.description')"
                  :error-messages="errors.description"
                  :prepend-icon="isChanged('description') ? 'pencil' : null"
                  @input="registerChange('description')"
              />
            </div>
            <div class="flex xs12 mt">
              <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                   @click="update" v-slashes>{{ __('common.save_changes') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import { editFormMixin } from "../../mixins/editForm";

  export default {
    mixins: [localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/ingame/items/itemreport',
      emptyRecord: {
        id: null,
        itemId: null,
        description: '',
        createdAt: '---',
        updatedAt: '---'
      },
    }),
  }
</script>