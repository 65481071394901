<template>
  <div class="itemReports view">
    <app-header :title="__('itemReports')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search mr ml">
        <select-reference
            v-model="itemId"
            :endpoint="'/ingame/items/item'"
            :nothing-selected-message="__('filterByItem')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="userId"
            :endpoint="'/community/user'"
            :field-title="'userName'"
            :field-icon="'avatar'"
            :nothing-selected-message="__('filterByUser')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh()" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button" :title="__('reportItem')" @click="showReportItem" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/report.svg"
               alt="report"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="itemReports"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @editItemReport="editItemReport"
          @editItem="editItem"
          @sort="sort"
          @mergeItemReport="mergeItemReport"
          @declineItemReport="declineItemReport"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-item-report ref="editItemReport" @updated="refresh" @created="sayThankYouForItemReport"/>
    <edit-item ref="editItem" @updated="refresh"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import EditItemReport from "../../components/Ingame/EditItemReport";
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import { userMixin } from "../../mixins/user";
  import EditItem from "../../components/Ingame/EditItem";

  const renderStatus = (value) => {
    switch (value) {
      case null:
        return '<span class="ribbon info">open</span>'

      case 'merged':
        return '<span class="ribbon success">merged</span>'

      case 'declined':
        return '<span class="ribbon error">declined</span>'

      default:
        return '<span class="ribbon warning">value</span>'
    }
  }

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin, userMixin],
    components: {
      EditItem,
      EditItemReport,
      AppHeader,
      DataTable,
    },
    data: () => ({
      loading: false,
      itemReports: [],
      userId: null,
      itemId: null,
      dataTableFields: [],
      recordActions: [],
    }),
    watch: {
      userId() {
        this.pagination.page = 1;
      },
      itemId() {
        this.pagination.page = 1;
      },
    },
    methods: {
      sayThankYouForItemReport() {
        this.refresh();
        this.$refs.editItemReport.close();
        Swal.fire({
          title: this.__('thankYou'),
          html: this.__('sayThankYouForOreAppearanceReport').replace('$user', this.$store.state.user.username),
          type: 'success',
        });
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        if (this.userId !== null) {
          params.userIds = this.userId;
        }
        if (this.itemId !== null) {
          params.itemIds = this.itemId;
        }
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/items/itemreport', { params }).then(async (res) => {
          this.itemReports = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification('Item reports loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        const itemIds = [];
        const userIds = []
        let items = [];
        let users = [];
        this.itemReports.forEach(itemReport => {
          if (!itemIds.includes(itemReport.itemId)) {
            itemIds.push(itemReport.itemId);
          }
          if (!userIds.includes(itemReport.userId)) {
            userIds.push(itemReport.userId);
          }
        });
        const params = {
          fields: 'id,fullName,icon'
        };
        if (itemIds.length > 0) {
          await apiSecured.get(`/ingame/items/item/(${[...new Set(itemIds)].join(',')})`, { params }).then((res) => {
            items = res.data;
            this.showInfoNotification('Items have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        if (userIds.length > 0) {
          await apiSecured.get(`/community/user/(${[...new Set(userIds)].join(',')})`, {
            params: {
              fields: 'id,userName,avatar'
            }
          }).then((res) => {
            users = res.data;
            this.showInfoNotification('Users have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        this.itemReports = this.itemReports.map(itemReport => {
          const item = items.find(item => item.id === itemReport.itemId);
          const user = users.find(user => user.id === itemReport.userId);

          itemReport.itemIcon = item.icon;
          itemReport.itemName = item.fullName;
          itemReport.userAvatar = user.avatar ? user.avatar : 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
          itemReport.userName = user.userName;

          return itemReport;
        })
      },
      showReportItem() {
        this.$refs.editItemReport.showCreate();
      },
      editItemReport(itemReport) {
        this.$refs.editItemReport.showEdit(itemReport);
      },
      editItem(itemReport) {
        this.$refs.editItem.showEdit({id: itemReport.itemId});
      },
      mergeItemReport(report) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('itemReportDoYouWantToMerge'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.just_du_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = `/ingame/items/itemreport/${report.id}/merge`;
            apiSecured.patch(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('merged'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
      declineItemReport(report) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('itemReportDoYouWantToDecline'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.just_du_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = `/ingame/items/itemreport/${report.id}/decline`;
            apiSecured.patch(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('declined'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      }
    },
    created() {
      this.setPageTitle(this.__('itemReports'));
      if ('userId' in this.$route.query) {
        const userId = this.$route.query.userId;
        if (userId !== null && userId !== undefined) {
          this.userId = userId;
        }
      }
      if ('itemId' in this.$route.query) {
        const itemId = this.$route.query.itemId;
        if (itemId !== null && itemId !== undefined) {
          this.itemId = itemId;
        }
      }
      this.dataTableFields = [
        { name: "itemIcon", title: '', type: "image", disableSorting: true },
        {
          name: "itemName",
          title: this.__('entity.common.item'),
          type: "default",
          disableSorting: true
        },
        { name: "userAvatar", title: '', type: "image", disableSorting: true },
        { name: "userName", title: this.__('entity.common.user'), type: "default", disableSorting: true },
        { name: "status", title: 'Status', type: "custom", render: renderStatus },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        {
          title: this.__('editItemReport'),
          icon: 'pencil',
          event: 'editItemReport',
          vif: (record) => {
            return record.status === null && (this.hasOneRoles(['InGame Items Editor']) || record.userId === this.$store.state.user.id)
          }
        },
        {
          title: this.__('merge'), icon: 'check', event: 'mergeItemReport',
          vif: (record) => {
            return record.status === null && this.hasOneRoles(['InGame Items Editor'])
          }
        },
        {
          title: this.__('decline'), icon: 'cross', event: 'declineItemReport',
          vif: (record) => {
            return record.status === null && this.hasOneRoles(['InGame Items Editor'])
          }
        },
        {
          title: this.__('editItem'),
          icon: 'pencil',
          event: 'editItem',
          vif: (record) => {
            return record.status === null && (this.hasOneRoles(['InGame Items Editor']))
          }
        },
      ];
      this.refresh();
    }
  }
</script>
